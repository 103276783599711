import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-gresham-oregon.png'
import image0 from "../../images/cities/scale-model-of-harlow-house-museum-in-gresham-oregon.png"
import image1 from "../../images/cities/scale-model-of-caswell-sculpture-in-gresham-oregon.png"
import image2 from "../../images/cities/scale-model-of-blue-lake-regional-park-in-gresham-oregon.png"
import image3 from "../../images/cities/scale-model-of-columbia-view-park-in-gresham-oregon.png"
import image4 from "../../images/cities/scale-model-of-cherry-blossom-park-in-gresham-oregon.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Gresham'
            state='Oregon'
            image={image}
            lat='45.5023'
            lon='-122.4416'
            attractions={ [{"name": "Harlow House Museum", "vicinity": "726 E Historic Columbia River Hwy, Troutdale", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 45.5381565, "lng": -122.38249769999999}, {"name": "Caswell Sculpture", "vicinity": "903 E Historic Columbia River Hwy, Troutdale", "types": ["art_gallery", "point_of_interest", "establishment"], "lat": 45.5372913, "lng": -122.38041240000001}, {"name": "Blue Lake Regional Park", "vicinity": "20500 NE Marine Dr, Fairview", "types": ["park", "point_of_interest", "establishment"], "lat": 45.5569634, "lng": -122.4507514}, {"name": "Columbia View Park", "vicinity": "NE 172nd Ave, Portland", "types": ["park", "point_of_interest", "establishment"], "lat": 45.5299382, "lng": -122.48765839999999}, {"name": "Cherry Blossom Park", "vicinity": "1920 SE 97th Ave, Portland", "types": ["park", "point_of_interest", "establishment"], "lat": 45.50912599999999, "lng": -122.56195830000001}] }
            attractionImages={ {"Harlow House Museum":image0,"Caswell Sculpture":image1,"Blue Lake Regional Park":image2,"Columbia View Park":image3,"Cherry Blossom Park":image4,} }
       />);
  }
}